"use client";

import { forwardRef, useImperativeHandle, useState } from "react";
import DPIcon from "../DPIcon/DPIcon";
import { purify } from "@/helpers";

const SimpleToaster = forwardRef(function SimpleToaster({ duration }: {
  readonly duration?: number,
}, ref) {
  const [ published, setPublished ] = useState<{ id: string, visible: boolean, content: string, success?: 'success'|'error'|'warning' }[]>([]);
  const defaultDuration = 3000;
  const animDuration = 300;
  const animClass = 'duration-' + animDuration;

  useImperativeHandle(ref, () => {
    return {
      publish: (content: string, success?: 'success'|'error'|'warning') => {
        setPublished((current) => {
          const items = [ ...current ];
          const id = Date.now() + '_' + items.length;
          items.push({ id, visible: false, content });

          setTimeout(() => {
            setPublished((current) => {
              const items = current.map((item) => {
                if(item.id === id) item.visible = true;
                item.success = success ?? 'error';
                return item;
              });
              
              return items;
            });
          }, 50);

          setTimeout(() => {
            setPublished((current) => {
              const items = current.map((item) => {
                if(item.id === id) item.visible = false;
                return item;
              });
              
              return items;
            });
          }, (duration ?? defaultDuration) + 50);

          setTimeout(() => {
            setPublished((current) => {
              const items = current.filter((item) => item.id !== id);

              return items;
            });
          }, (duration ?? defaultDuration) + animDuration + 50);

          return items;
        });
      }
    };
  }, [ duration ]);

  return (
    <div className="fixed right-4 top-[85px] lg:top-[120px] w-0 h-0 overflow-visible z-[9999]">
      <div className="flex flex-col justify-start items-end gap-3">
        {
          (published ?? []).map(({ content, visible, id, success }) => (
            <div key={id} className={`relative flex items-center gap-3 w-[calc(100svw-32px)] max-w-[360px] min-w-[240px] h-fit px-5 py-4 ${success === 'success' ? 'bg-green-RYB' : (success === 'error' ? 'bg-blush' : 'bg-amber')} text-white rounded-lg transition-all ${animClass} ${visible ? 'opacity-100 shadow-lg' : 'opacity-0'}`}>
              <DPIcon icon="warning" className="text-2xl" />
              <div className="text-sm" dangerouslySetInnerHTML={purify(content)}></div>
            </div>
          ))
        }

      </div>
    </div>
  );
});

export default SimpleToaster;