"use client";

import { stripTags } from "@/helpers";

const strip = (data: any) => {
  if(!data) return '';

  if(typeof data === 'string') {
    data = stripTags(data);
  } else if(Array.isArray(data)) {
    data = data.map(strip);
  } else if(typeof data === 'object' && Object.keys(data).length > 0) {
    for(let i in data) {
      data[i] = strip(data[i]);
    }
  }

  return data;
};

export default function JsonLd({ data }: {
  readonly data?: Record<string,any>
}) {
  if(!data) return (<></>);
  
  const stripped = strip({ ...data });
  
  return (
    <section>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(stripped) }}
      />
    </section>
  );
}
