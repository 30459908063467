"use client";

import { forwardRef } from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import classNames from "classnames";
import DPIcon from "../DPIcon/DPIcon";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = ({
  className,
  ...props
}: any) => (
  <DialogPrimitive.Portal className={className} {...props} />
);

DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = forwardRef(({ className, ...props }: any, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={classNames(
      "fixed inset-0 z-30 bg-background/80 backdrop-blur-sm data-[state=open]:animate-fade-in data-[state=closed]:animate-fade-out",
      className
    )}
    {...props}
  />
));

DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = forwardRef(({ className, children, hideCloseButton, ...props }: any, ref: any) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={classNames(
        "fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] border bg-background shadow-lg duration-200 data-[state=open]:animate-fade-in data-[state=closed]:animate-fade-out sm:rounded-lg",
        className
      )}
      {...props}
    >
      {children}
      { !hideCloseButton && (
        <DialogPrimitive.Close className="absolute right-4 top-4 outline-none outline-0 outline-transparent">
          <DPIcon icon="close" />
        </DialogPrimitive.Close>
      )}
    </DialogPrimitive.Content>
  </DialogPortal>
));

DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: any) => (
  <div
    className={classNames(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    )}
    {...props}
  />
);

DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: any) => (
  <div
    className={classNames(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
);

DialogFooter.displayName = "DialogFooter";

const DialogTitle = forwardRef(({ className, ...props }: any, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={classNames(
      "text-lg font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
));

DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = forwardRef(({ className, ...props }: any, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={classNames("text-sm text-muted-foreground", className)}
    {...props}
  />
));

DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription
};
