import { flattenData } from "../formatters";
import { applyPackageExternalInfo, fetchData, fetchSingleFlatData, pricingObject } from "./data";

let revalidate: number = 86400;
try {
  revalidate = parseInt(process.env.NEXT_PUBLIC_PACKAGE_API_REVALIDATION_INTERVAL ?? '86400');
} catch(e) {
  revalidate = 86400;
}

const fetchPackageDetail = async (operation: string, fieldName: string, id: string|number, def: any): Promise<any> => {
  const params: Record<string,any> = { operation };
  params[fieldName] = id;
  const body = JSON.stringify(params);

  const res = await fetch(
    `${process.env.PACKAGE_API}`,
    {
      method: "POST",
      body,
      next: { revalidate },
      headers: {
        'x-api-key': `${process.env.PACKAGE_API_AUTH_TOKEN}`
      }
    }
  ).then((res) => {
    try {
      return res.json();
    } catch(err) {
      return def;
    }
  }).catch(() => {
    return def;
  });

  if(res?.errorType) return def;

  return res;
};

export type CurriculumItem = {
  "curriculumId": number,
  "curriculumName": string,
  "totalUnitCount": number,
  "totalTopicCount": number,
  "totalContentCount": number,
  "totalContentDuration": number,
  "totalInteractiveContentDuration"?: number,
  "totalTestCount": number,
  "totalVasCount": number,
  "totalItemCount": number,
  "info"?: Record<string,any>
  "units"?: UnitItem[],
};
export const fetchCurriculums = async (id: string|number): Promise<CurriculumItem[]> => {
  return await fetchPackageDetail('packageCurriculums', 'packageId', id, []);
};

export type UnitItem = {
  "unitId": number,
  "unitName": string,
  "totalTopicCount": number,
  "totalContentDuration": number,
  "totalInteractiveContentDuration"?: number,
  "totalContentCount": number,
  "totalItemCount": number,
  "materials"?: MaterialItem[],
};
export const fetchUnits = async (id: string|number): Promise<UnitItem[]> => {
  return await fetchPackageDetail('unitsByCurriculum', 'curriculumId', id, []);
};

export type MaterialItem = {
  "materialId": string,
  "materialName": string,
  "materialType": string,
  "totalItemCount"?: number,
  "duration"?: number,
  "contentType"?: string,
  "quizType"?: string,
};
export const fetchMaterials = async (id: string|number): Promise<MaterialItem[]> => {
  return await fetchPackageDetail('materialsByUnit', 'unitId', id, []);
};

export type CreditItem = {
  "appName": string,
  "totalCredits": number,
  "appointmentMeetingExist": number,
  "details": {
    "creditType": string,
    "credit": number
  }[],
};
export const fetchCredits = async (id: string|number): Promise<CreditItem[]> => {
  return await fetchPackageDetail('packageCredits', 'packageId', id, []);
};

export type SummaryItem = {
  "totalUnitCount": number,
  "totalTopicCount": number,
  "totalContentCount": number,
  "totalContentDuration": number,
  "totalInteractiveContentDuration": number,
  "totalTestCount": number,
  "totalVasCount": number,
  "totalItemCount": number,
  "totalVideoCount"?: number,
  "totalInteractiveVideoCount"?: number,
};
export const fetchSummary = async (id: string|number): Promise<SummaryItem> => {
  return await fetchPackageDetail('curriculumSummaryByPackage', 'packageId', id, {});
};

export type FullCurriculum = {
  summary: SummaryItem,
  credits: Record<string, Record<string,any>>,
  curriculum: CurriculumItem[]
};
export const fetchFullCurriculum = async (id: string|number, addUnits?: boolean): Promise<FullCurriculum> => {
  const answer = {
    "summary": await fetchSummary(id),
    "credits": {},
    "curriculum": await fetchCurriculums(id)
  };
  const credits = await fetchCredits(id);
  
  let totalVideoCount = 0;
  let totalInteractiveVideoCount = 0;
  for(let i in answer.curriculum) {
    let info = await fetchSingleFlatData('lessons', { 'filters': { 'curriculum_id': { '$eq': answer.curriculum[i].curriculumId } } }, {});

    /* If info could not be found, create one */
    if(!Object.keys(info).includes('caption') && answer.curriculum[i]?.curriculumName && answer.curriculum[i]?.curriculumId) {
      try {
        await fetch(
          `${process.env.API_URL}/lessons`,
          {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${process.env.API_TOKEN}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "data": {
                "caption": answer.curriculum[i].curriculumName,
                "curriculum_id": answer.curriculum[i].curriculumId,
              }
            })
          }
        );
      } catch(e) {}
    }
    /* -o- */

    answer.curriculum[i].info = info;
    // We may put an api call here to create unfound curriculums on our side to add content
    const units = addUnits ? await fetchUnits(answer.curriculum[i].curriculumId) : [];
    for(let j in units) {
      units[j].materials = await fetchMaterials(units[j].unitId);
      units[j].materials?.forEach((material: MaterialItem, index: number) => {
        material.materialId = answer.curriculum[i].curriculumId + '-' + units[j].unitId + '-' + index;
      });
    }
    answer.curriculum[i].units = units;
  }
  answer.summary.totalVideoCount = totalVideoCount;
  answer.summary.totalInteractiveVideoCount = totalInteractiveVideoCount;

  answer.credits = buildCreditObject(credits);

  return answer;
};

export const buildCreditObject = (credits: CreditItem[]): Record<string,any> => {
  const creditObj: Record<string, Record<string,any>> = {};
  
  credits?.forEach((credit: Record<string,any>) => {
    if(credit?.appName) {
      const details: Record<string,any> = {};
      credit?.details?.forEach((detail: Record<string,any>) => {
        details[detail.creditType] = detail.credit;
      });
      credit.details = details;
      creditObj[credit.appName] = credit;
    }
  });

  return creditObj;
};

export const durationSeconds = (duration: number): number => {
  return Math.round(Math.max(0, duration) / 1000);
};

export const durationMinutes = (duration: number): number => {
  return Math.floor(durationSeconds(duration) / 60);
};

export const fetchAllPackages = async (): Promise<Record<string,any>[]> => {
  let packages: Record<string,any>[] = [];
  let start = 0;
  const limit = 25;
  while(true) {
    let p = await fetchData('packages', {
      pagination: {
        start: start * limit,
        limit,
      }
    });
    if(p?.data?.length) packages = [ ...packages, ...p.data as [] ];
    if(((start + 1) * limit) >= (p?.meta?.pagination?.total ?? 0)) break;
    start++;
  }
  packages = flattenData(packages, true) as [];

  const pricing = await pricingObject();
  packages = await applyPackageExternalInfo(pricing, packages) as [];

  return packages;
};