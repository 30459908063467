import { PageData } from "@/types/page";
import qs from "qs";
import { flattenData } from "../formatters";
import { buildCreditObject, fetchCredits, fetchSummary } from "./package";

let revalidate: number = 86400;
try {
  revalidate = parseInt(process.env.NEXT_PUBLIC_REVALIDATION_INTERVAL ?? '86400');
} catch(e) {
  revalidate = 86400;
}

export const fetchData = async (root: string, query?: Record<string, any>, populate?: string | string[] | Record<string,any>): Promise<PageData> => {
  if(!populate) populate = '*';
  
  const jsonQuery: Record<string, any> = query ?? {};
  jsonQuery.populate = populate;

  const strQuery = qs.stringify(jsonQuery);

  const res = await fetch(
    `${process.env.API_URL}/${root}?${strQuery}`,
    {
      next: { revalidate },
      headers: {
        'Authorization': `Bearer ${process.env.API_TOKEN}`
      }
    }
  ).then((res) => {
    try {
      return res.json();
    } catch(err) {
      return { data: {} };
    }
  }).catch(() => {
    return { data: {} };
  });
    
  return res;
};

export const fetchFlatData = async (root: string, query?: Record<string, any>, populate?: string | string[] | Record<string,any>): Promise<Record<string,any>> => {
  const res = await fetchData(root, query, populate);
    
  return flattenData(res.data, true) ?? {};
};

export const fetchSingleFlatData = async (root: string, query?: Record<string, any>, populate?: string | string[] | Record<string,any>): Promise<Record<string,any>> => {
  let res = await fetchFlatData(root, query, populate);
  
  if(Array.isArray(res)) res = res.shift();

  return res ?? {};
};

export const pricingFetcher = async () => {
  return await fetch(
    `${process.env.PAYMENT_API}?token=${process.env.PAYMENT_API_PRICING_TOKEN}&operation=${process.env.PAYMENT_API_PRICING_OPERATION ?? 'packetlistWeb'}`,
    {
      next: { revalidate: parseInt(process.env.NEXT_PUBLIC_PAYMENT_API_REVALIDATION_INTERVAL ?? '86400') }
    }
  ).then((res) => {
    try {
      return res.json();
    } catch(err) {
      return [];
    }
  }).catch(() => {
    return [];
  });
};

export const pricingObject = async () => {
  const pricingList = await pricingFetcher();
  const pricingObject: Record<string, any> = {};
  pricingList?.forEach((item: Record<string,any>) => {
    item.packageStrikePrice = Math.max(0, (Math.round((item?.packagePrice ?? 0) * Number(process.env.NEXT_PUBLIC_PRODUCT_COEFFICIENT ?? 1.3) / 100) * 100) - 1);
    pricingObject[item.packageId] = item;
  });

  return pricingObject;
};

export const applyPackageExternalInfo = async (pricing: Record<string,any>, packages: Record<string,any>|Record<string,any>[]) => {
  const retType = Array.isArray(packages) ? 'array' : 'object';
  let list: Record<string,any>[] = Array.isArray(packages) ? packages : [ packages ];

  list = list.filter((item: Record<string,any>) => {
    if(!pricing?.[item?.external_id]) {
      if(!(item?.prevent_sales && item?.coming_soon)) return false;
    }

    return true;
  });
  list = list.filter((item: Record<string,any>) => (!item.prevent_sales || item.coming_soon) ? true : false);

  for(let i in (list ?? [])) {
    const price: Record<string,any>|null = pricing[list[i].external_id] ?? null;
    if(price) {
      list[i].cancelled_price = Number(price['packageStrikePrice']) ?? 0;
      list[i].price = Number(price['packagePrice']) ?? 0;
    }

    if(!list[i]?.curriculums) {
      let credits = await fetchCredits(list[i].external_id);
      list[i].curriculums = {
        summary: await fetchSummary(list[i].external_id),
        credits: buildCreditObject(credits),
      };
    }
  }

  if(retType !== 'array') return list[0] ?? null;

  return list;
};