"use client";

import _e from "@/helpers/texts";
import { fetchMailer } from "@/helpers/fetchers/api-fetcher";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import {Dialog, DialogContent} from "@/components/ui/Dialog";
import { ScrollArea } from '@/components/ui/ScrollArea';
import { purify } from "@/helpers";
import SimpleToaster from "@/components/SimpleToaster/SimpleToaster";
import DPIcon from "@/components/DPIcon/DPIcon";
import "./css/user-form-dialog.scss";
import "@/components/CommonLoader/css/loader.scss";

interface IFormData {
  name: string;
  phone: string;
  department: string;
  message: string;
}

interface IFormErrors {
  name?: string;
  phone?: string;
  message?: string;
}

const BuyInfoDialog = forwardRef(function BuyInfoDialog ({}, ref) {

  const backdrop = useRef<any>(null);

  const [ isLoaded, setIsLoaded ] = useState<boolean>(false);
  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const [ department, setDepartment ] = useState<string>('common');
  const [ caption, setCaption ] = useState<string>('');
  const [ subCap, setSubCap ] = useState<string>(_e('Detaylı bilgi almak için lütfen aşağıdaki formu doldurunuz.'));
  const [ successCaption, setSuccessCaption ] = useState<string>(_e('Teşekkürler'));
  const [ successMessage, setSuccessMessage ] = useState<string>(_e('Bilgilerinizi bizimle paylaştığınız için teşekkürler. Sizi en kısa sürede arayacağız.'));
  
  useImperativeHandle(ref, () => {
    return {
      open: (department: string, caption?: string, subCap?: string, successCaption?: string, successMessage?: string) => {
        if(caption) setCaption(caption);
        if(subCap) setSubCap(subCap);
        if(successCaption) setSuccessCaption(successCaption);
        if(successMessage) setSuccessMessage(successMessage);
        setDepartment(department);
        setIsOpen(true);
      },
      close: () => setIsOpen(false),
    };
  }, []);

  const getContract = async (type: string, def: Record<string,any>) => {
    let res: Record<string,any>;
    try {
      res = await fetch(`/api/contract?type=${type}`)
        .then((res) => res.json())
        .catch(() => { return def; });
    } catch(e) {
      return def;
    }

    if(typeof res !== 'object') return def;

    if(!res?.title) res.title = def?.title ?? '';
    if(!res?.content) res.content = def?.content ?? '';

    return res;
  };

  const [ contracts, setContracts ] = useState<Record<string,Record<string,any>>>({});
  const [ contractsFilled, setContractsFilled ] = useState<boolean>(false);

  const toasterRef = useRef<any>(null);
  const [ sending, setSending ] = useState<boolean>(false);

  const [ isShort, setIsShort ] = useState<boolean>(false);
  const [ isSent, setIsSent ] = useState<boolean>(false);

  const [contractDialog, setContractDialog] = useState<boolean>(false);
  const [consentDialog, setConsentDialog] = useState<boolean>(false);
  const [isConsentChecked, setIsConsentChecked] = useState<boolean>(false);

  const nameRegex = /^[A-Za-zÇŞĞÜÖİçşğüöı]+( [A-Za-zÇŞĞÜÖİçşğüöı]+){1,2}$/;
  const phoneRegex = /^\([1-9][0-9][0-9]\) \d{3} \d{2} \d{2}$/;
  const messageRegex = /^.{1,1023}$/;

  const [formData, setFormData] = useState<IFormData>({
    name: '',
    phone: '',
    message: '',
    department,
  });

  // Hatalar için state
  const [errors, setErrors] = useState<IFormErrors>({});

  const formatPhoneNumber = (input:any) => {
    let digits = input.replace(/\D/g, '');
    if(!digits) return '';

    if(digits.slice(0,1) === '9') digits = digits.substring(1);
    if(!digits) return '';

    digits = (Number(digits)).toString();
  
    if (digits.length < 4) return digits;
    if (digits.length < 7) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    if (digits.length < 9) return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)} ${digits.slice(6)}`;
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)} ${digits.slice(6, 8)} ${digits.slice(8, 10)}`;
  };

  const handleChange = (e:any) => {
    const { name, value } = e.target;

    if (name === 'name' && nameRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, name: undefined }));
    }

    if (name === 'phone') {
      const formattedValue = formatPhoneNumber(value);
      setFormData((prevState) => ({ ...prevState, [name]: formattedValue }));
  
      setErrors((prevState) => ({ ...prevState, phone: undefined }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }

    if (name === 'message' && messageRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, message: undefined }));
    }
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();

    formData.department = department;

    const newErrors: IFormErrors = {};

    if (!formData.name || !nameRegex.test(formData.name)) {
      newErrors.name = _e("Lütfen geçerli bir ad soyad giriniz.");
    }
    if (formData.name?.length > 255) {
      newErrors.name = _e("İsim bilgisi :len karakterden uzun olamaz.", { len: 255 });
    }
    
    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      newErrors.phone = _e("Lütfen geçerli bir telefon numarası giriniz.");
    }

    if (!formData.message) {
      newErrors.message = _e("Lütfen bir mesaj giriniz.");
    }
    if (formData.message?.length > 1023) {
      newErrors.name = _e("Mesaj :len karakterden uzun olamaz.", { len: 1023 });
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});

      if(!isConsentChecked) {
        toasterRef.current.publish(_e('Lütfen Açık Rıza Metni\'ni kabul ediniz.'));
        setSending(false);
        return false;
      }
  
      (async () => {
        setSending(true);

        const res = await fetchMailer(formData);

        if (res.status === 200) {
          const response = await res.data;
          if(response?.success) {
            setIsSent(true);
            
            let interval = Number(process.env.NEXT_PUBLIC_INTERVAL_PER_CONTACT_FORM_IN_MINUTES ?? 1);
            if(!interval || isNaN(interval)) interval = 1;
            interval = Math.max(1, interval);
            setTimeout(() => {
              setSending(false);
              setIsSent(false);
            }, interval * 60000);
          } else if(response?.error) {
            toasterRef.current.publish(response.error);
            setSending(false);
          } else {
            toasterRef.current.publish(_e('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.'));
            setSending(false);
          }
        } else {
          toasterRef.current.publish(_e('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.'));
          setSending(false);
        }
      })();
    }
  };

  useEffect(() => {
    setIsConsentChecked(false);

    const handleResize = () => {
      setIsShort(window?.innerHeight < 900);
    };

    handleResize();

    (async () => {
      const salesFormInformation = await getContract('sales-form-information', {
        "title": "DOPİNG HAFIZA KULLANICI AYDINLATMA METNİ",
        "content": "",
      });
      const consent = await getContract('consent', {
        "title": "DOPİNG HAFIZA KULLANICI AÇIK RIZA METNİ",
        "content": "",
      });

      setContracts({ salesFormInformation, consent });
      setContractsFilled(true);
    })();

    setTimeout(() => setIsLoaded(true), 100);

    window?.addEventListener('resize', handleResize);
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      ref={backdrop}
      className={`${isLoaded ? 'fixed' : 'hidden'} flex justify-center items-center bg-[rgba(0,0,0,.3)] transition-opacity ${isOpen ? 'opacity-1 z-[10001] top-0 bottom-0 left-0 w-full' : '-z-[1] opacity-0 -top-[9999px] -left-[9999px] !w-0 !max-w-0'} overflow-hidden`}
      onClick={ (e) => { if(e?.target === backdrop?.current) setIsOpen(false); } }
    >
      <div className={`relative w-[396px] max-w-[94%] max-h-[94%] py-1 rounded-lg bg-cultured shadow-xl overflow-auto custom-scroll`}>
        {
          !contractsFilled && (
            <div className={`absolute top-0 right-0 bottom-0 left-0 w-full h-full z-[9] rounded-lg bg-cultured flex justify-center items-center opacity-90`}>
              <div className="__commonLoader transition-all duration-300 bg-[#f9f9f933] absolute top-0 right-0 bottom-0 left-0 w-full h-full z-[999999]" />
            </div>
          )
        }

        <div className={`absolute top-[8px] right-[8px] cursor-pointer z-10`}>
          <button onClick={() => setIsOpen(false) }>
            <DPIcon icon="close" />
          </button>
        </div>

        {
          isSent ? (
            <div className={`contact-dialog-body relative w-full px-6 max-w-full flex flex-col items-center ${isShort ? 'pt-[45px] pb-[30px]' : 'pt-[60px] pb-[60px]'} px-[30px]`}>
              <div>
                <h2 className={`text-honolulu-blue text-[20px] font-semibold my-2 text-center`}>{ successCaption ?? _e('Teşekkürler') }</h2>
                <p className={`text-center text-[#686868]`}>
                  { successMessage ?? _e('Bilgilerinizi bizimle paylaştığınız için teşekkürler. Sizi en kısa sürede arayacağız.') }
                </p>
              </div>

              <div className="flex justify-center w-full mt-10">
                <button onClick={() => setIsOpen(false) } className={`py-2 px-4 rounded-md transition-all bg-honolulu-blue hover:opacity-95 text-sm tracking-wide text-white font-medium`}>
                  { _e('Kapat') }
                </button>
              </div>
            </div>
          ) : (
            <div className={`contact-dialog-body relative w-full px-6 max-w-full flex flex-col items-center ${isShort ? 'pt-[30px]' : 'pt-[60px]'} pb-[30px]`}>
              <div className="flex flex-col items-center">
                <h2 className={`text-[#1C1442] text-center ${isShort ? 'text-[16px]' : 'text-[20px]'} font-semibold my-2`}>{ caption ?? '' }</h2>
                <p className={`text-center text-[#686868] ${isShort ? 'text-sm' : 'text-base'}`}>
                  { subCap ?? _e('Detaylı bilgi almak için lütfen aşağıdaki formu doldurunuz.') }
                </p>
              </div>

              <form className="flex flex-col w-full" onSubmit={handleSubmit}>
                <div className={`${isShort ? 'mt-[14px]' : 'mt-[30px]'}`}>
                  <label htmlFor="name" className="text-[#949BA5] text-sm">
                    { _e('Adı & Soyadı') }
                  </label>
                  <input 
                    type="text" 
                    maxLength={255}
                    className={`w-full border rounded-lg py-2 px-4 h-12 ${errors.name ? 'border-lava bg-[#FDECEA]' : 'border-[#BDBDBD] bg-[#FAF9FF]'}`} 
                    id="name"
                    name="name"
                    onChange={handleChange}
                    value={formData.name}
                  />
                  {errors.name && <p className="error-message text-xs text-lava mt-[2px] h-0 overflow-visible">{errors.name}</p>}
                </div>

                <div className={`${isShort ? 'mt-[20px]' : 'mt-[30px]'}`}>
                  <label htmlFor="phone" className="text-[#949BA5] text-sm">
                    { _e('Telefon') }
                  </label>
                  <input 
                    type="text"
                    className={`w-full border rounded-lg py-2 px-4 h-12 ${errors.name ? 'border-lava bg-[#FDECEA]' : 'border-[#BDBDBD] bg-[#FAF9FF]'}`} 
                    id="phone"
                    name="phone"
                    maxLength={255}
                    onChange={handleChange}
                    value={formData.phone}
                    max={10}
                  />
                  {errors.phone && <p className="error-message text-xs text-lava mt-[2px] h-0 overflow-visible">{errors.phone}</p>}
                </div>

                <div className={`${isShort ? 'mt-[20px]' : 'mt-[30px]'}`}>
                  <label htmlFor="message" className="text-[#949BA5] text-sm">
                    { _e('Notunuz') }
                  </label>
                  <textarea
                    className={`w-full border rounded-lg py-2 px-4 ${isShort ? 'h-[66px]' : 'h-[88px]'} ${errors.name ? 'border-lava bg-[#FDECEA]' : 'border-[#BDBDBD] bg-[#FAF9FF]'}`} 
                    id="message"
                    name="message"
                    onChange={handleChange}
                    value={formData.message}
                    maxLength={1023}
                  ></textarea>
                  {errors.message && <p className="error-message text-xs text-lava -mt-1 h-0 overflow-visible">{errors.message}</p>}
                </div>

                <div className="text-[13px] text-[#949BA5] mt-6 mb-2">
                  * Kişisel verileriniz <span onClick={() => setContractDialog(true)} className="underline cursor-pointer font-medium text-honolulu-blue">Aydınlatma Metni</span> kapsamında işlenmektedir.
                </div>

                <div className="flex justify-start items-start gap-2 text-[13px] text-[#949BA5] my-2">
                  <div>
                    <input
                      className="block m-0 hover:cursor-pointer w-5 h-5 border-0 rounded-md honolulu-blue"
                      type="checkbox"
                      id="consent-check"
                      checked={isConsentChecked}
                      onChange={ () => setIsConsentChecked((current: boolean) => { return !current; }) }
                    />
                  </div>
                  <div className="text-xs select-none cursor-pointer">
                    <span onClick={(e:any) => {
                      e.stopPropagation();
                      setConsentDialog(true);
                    }} className="underline cursor-pointer font-medium text-honolulu-blue">Açık Rıza Metni</span>
                    <span className="" onClick={ () => setIsConsentChecked((current: boolean) => { return !current; }) }>&rsquo;ni ve Ticari İleti iletilmesini kabul ediyorum.</span>
                  </div>
                </div>
              </form>

              <div className="flex justify-center w-full mt-6">
                <button onClick={handleSubmit} className={`py-2 px-4 rounded-md transition-all bg-honolulu-blue hover:opacity-95 text-sm tracking-wide text-white font-medium ${isLoaded ? '' : '!grayscale !pointer-events-none'} ${sending || !isConsentChecked ? '!grayscale !pointer-events-none' : ''}`}>
                  { _e('Bilgilerimi Gönder') }
                </button>
              </div>
            </div>
          )
        }
      
        {
          contractsFilled && (
            <span onKeyDown={(e) => e.stopPropagation()}>
              <Dialog open={contractDialog} onOpenChange={setContractDialog}>
                <DialogContent className="z-[10002] w-[calc(100svw-32px)] lg:w-2/3 xl:w-1/2 bg-white rounded-md overflow-hidden">
                  <h2 className='m-0 py-[15px] ps-4 pe-9 text-red-600 font-semibold bg-cultured'>{ contracts.salesFormInformation.title }</h2>
                  <ScrollArea className="h-[calc(100vh-240px)]">
                    <div className="px-4 max-w-[calc(100svw-32px)] __user_form_dialog" dangerouslySetInnerHTML={purify(contracts.salesFormInformation.content)}></div>
                  </ScrollArea>
                </DialogContent>
              </Dialog>

              <Dialog open={consentDialog} onOpenChange={setConsentDialog}>
                <DialogContent className="z-[10002] w-[calc(100svw-32px)] lg:w-2/3 xl:w-1/2 bg-white rounded-md overflow-hidden">
                  <h2 className='m-0 py-[15px] ps-4 pe-9 text-red-600 font-semibold bg-cultured'>{ contracts.consent.title }</h2>
                  <ScrollArea className="h-[calc(100vh-240px)]">
                    <div className="px-4 max-w-[calc(100svw-32px)] __user_form_dialog" dangerouslySetInnerHTML={purify(contracts.consent.content)}></div>
                  </ScrollArea>
                </DialogContent>
              </Dialog>
            </span>
          )
        }
      </div>
      <SimpleToaster ref={toasterRef} />
    </div>
  );
});

export default BuyInfoDialog;