export type ForcedEncryptionState = 'encrypted' | 'none-encrypted';

export const storageGet = async (key: string, def?: any, force_encryption_state?: ForcedEncryptionState): Promise<any> => {
  try {
    let val = localStorage.getItem(key);
    const encrypt = !force_encryption_state ? ((process.env.NEXT_PUBLIC_ENCRYPT_STORAGE ?? 'false') === 'true') : (force_encryption_state === 'encrypted');
    if(encrypt) {
      try {
        val = await fetch('/api/decrypt', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ value: val }),
          cache: 'no-store'
        }).then((res) => res.json());
      } catch(e) {
        return null;
      }
    }
    
    try {
      val = val ? JSON.parse(val) : def;
    } catch(e) {
      return val = def;
    }
    
    return val;
  } catch(e) {
    return def ?? null;
  }
};

export const storageSet = async (key: string, value: any, force_encryption_state?: ForcedEncryptionState): Promise<boolean> => {
  let val = JSON.stringify(value);
  
  const encrypt = !force_encryption_state ? ((process.env.NEXT_PUBLIC_ENCRYPT_STORAGE ?? 'false') === 'true') : (force_encryption_state === 'encrypted');
  if(encrypt) {
    try {
      val = await fetch('/api/encrypt', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ value: val }),
        cache: 'no-store'
      }).then((res) => res.json());
    } catch(e) { return false; }
  }

  try {
    localStorage.setItem(key, val);
  } catch(e) { return false; }

  return true;
};

export const storageUnset = (key: string) => {
  try {
    localStorage?.removeItem(key);
  } catch(e) {}
};

export default function useStorage() {
  return { storageGet, storageSet, storageUnset };
}