export const productDynamicValues: Record<string,string> = {
  'İçerik Sayısı': 'totalContentCount',
  'İçerik Süresi': 'totalContentDuration',
  'İnteraktif İçerik Süresi': 'totalInteractiveContentDuration',
  'İnteraktif Video Sayısı': 'totalInteractiveVideoCount',
  'Konu Sayısı': 'totalTopicCount',
  'Soru Sayısı': 'totalItemCount',
  'Test Sayısı': 'totalTestCount',
  'Ünite Sayısı': 'totalUnitCount',
  'Video Çözümlü Soru Sayısı': 'totalItemCount', // Instead of vas count
  'Video Sayısı': 'totalContentCount', // İçerik Sayısı ile aynı
  'Video Ardı Soru Sayısı': 'totalVasCount', // Instead of video count
};

export const productSpecialButtons: Record<string,string> = {
  'Çözücü': 'SOLVER',
  'Şimdi Anladım': 'GOT_IT',
  'Koçum Yanımda': 'COACH',
  'Online Eğitim': 'ONLINE_EDUCATION'
};
