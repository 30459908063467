export const fetchPayment = async (data: Record<string,any>): Promise<any> => {
  const res = await fetch('/api/payment', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    cache: 'no-store',
  });

  let answer: any = {
    status: 400,
    message: 'Hata oluştu.',
    data: null
  };

  if (res.status === 200) {
    answer.data = await res.json();
    answer.status = res.status;
    answer.message = 'Başarılı.';
  }

  return answer;
};

export const fetchMailer = async (data: Record<string,any>): Promise<any> => {
  data.name = data.name?.trim() ?? '';
  
  const res = await fetch('/api/mailer', {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    cache: 'no-store',
  });

  let answer: any = {
    status: 400,
    message: 'Hata oluştu.',
    data: null
  };

  if (res.status === 200) {
    answer.data = await res.json();
    answer.status = res.status;
    answer.message = 'Başarılı.';
  }

  return answer;
};
