"use client";

import { useEffect, useState } from "react";
import { Bag } from "@phosphor-icons/react";
import _e from "@/helpers/texts";
import { storageGet, storageSet } from "@/helpers/parsers/storage";
import LoadingLink from "../LoadingLink/LoadingLink";
import { hideLoader, showLoader } from "../CommonLoader/CommonLoader";
import { gtmSender } from "@/helpers";

export const getSelected = async () => {
  const selected = await storageGet("selectedItems", []);

  return selected;
};

export const addToCart = async (external_id: string|number, ecommerceId: string, price: number, itemName: string, categoryName: string, router?: any) => {
  if((process.env.NEXT_PUBLIC_SHOW_LOADER_ON_LINKS ?? 'false') === 'true') showLoader();

  const items = await getSelected();
  external_id = Number(external_id);

  if(!items.includes(external_id)) {
    items.push(external_id);
    await storageSet("selectedItems", items);

    const eventVal: Record<string,any> = {
      "event": "add_to_cart",
      "ecommerce": {
        "currency": "TRY",
        "value": price,
        "items": [
          {
            item_id: ecommerceId,
            item_name: itemName,
            price: price,
            item_brand: "Doping Hafıza",
            item_category: categoryName
          }
        ]
      },
      "userInformation": {
        "email": null
      }
    };

    let userData: any = await storageGet("EftData");
    if(userData && userData?.email) eventVal.userInformation.email = userData.email;

    gtmSender({ ecommerce: null });
    gtmSender(eventVal);
  }
  
  if(router) {
    if((process.env.NEXT_PUBLIC_SHOW_LOADER_ON_LINKS ?? 'false') === 'true') showLoader();
    router.push("/cart");
  } else {
    hideLoader();
  }
};

export default function CartLink(){
  const [cartItemsCount, setCartItemsCount] = useState(0);

  useEffect(() => {
    (async () => {
      const cartItems = await getSelected();
      setCartItemsCount(cartItems?.length ?? 0);
    })();

    const handleStorage = async () => {
      const cartItems = await getSelected();
      setCartItemsCount(cartItems?.length ?? 0);
    };

    window?.addEventListener('storage', handleStorage);

    return () => {
      window?.removeEventListener('storage', handleStorage);
    };
  },[]);
  
  return(
    <>
      { (() => {
        return cartItemsCount > 0 ? (
          <div className={`transform transition duration-300 delay-200 fixed top-[calc(50%-26px)] right-0 z-50`}>
            <LoadingLink href="/cart">
              <div className={`py-3 pl-2 pr-5 bg-blue-500 text-white rounded-l-2xl shadow-blue-500 hover:cursor-pointer relative group transition-all`}>
                <div className="relative flex items-center">
                  <div className="font-semibold w-0 group-hover:w-24 transition-all overflow-hidden whitespace-nowrap">{ _e('Sepete Git') }</div>
                  <Bag size={28} weight="bold"/>
                  <div className="absolute -right-3 -top-2 w-5 h-5 flex items-center justify-center bg-red-500 text-white text-[11px] rounded-full font-semibold">
                    {cartItemsCount}
                  </div>
                </div>
              </div>
            </LoadingLink>
          </div>
        ) : (<></>);
      })() }
    </>
  );
}